@use '../abstract/mixins'as mixin;
@use "../abstract/responsive"as rwd;

$c-amaranth: #E51553;
$c-athens-gray: #E7EAEE;
$c-mine-shaft-dark: #232323;
$c-white: #FFFFFF;

.imageText {
  @include mixin.marginTopBottom();;
  background-color: $c-athens-gray;
  /* max-height: 650px; */

  @include rwd.breakpoint(tabletmobile) {
    max-height: unset;
    padding: 23px 0 38px;
  }

  &.imageToRight {
    .imageText__image {
      -ms-order: 2;
      order: 2;

      @include rwd.breakpoint(tabletmobile) {
        -ms-order: unset;
        order: unset;
      }
    }
  }

  &.withoutBackgroundColor {
    background-color: transparent;

    .imageText__btnWrapper:after {
      background: linear-gradient(180deg, rgba(236, 237, 244, 0) 0%, $c-white 100%);
    }

    .btn {
      border: solid 1px transparent;

      &:hover,
      &:focus {
        @include rwd.breakpoint(smallUp) {
          border-color: var(--main-color);
          background-color: $c-white;
          color: var(--main-color);
        }
      }
    }
  }

  .smallMessage {
    @include mixin.font(12px, 14px, 700, $c-amaranth);
    letter-spacing: .5px;
    margin-top: 0;
    text-transform: uppercase;
  }

  .container {
    @include rwd.breakpoint(mobile) {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  header {
    @include rwd.breakpoint(mobile) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 58px;

    @include rwd.breakpoint(tabletmobile) {
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 8px;
    }
  }

  &__image,
  &__text {
    flex-basis: 50%;
    width: 50%;
    max-width: 50%;

    @include rwd.breakpoint(tabletmobile) {
      flex-basis: 100%;
      width: 100%;
      max-width: 100%;
    }
  }

  &__txtBox {
    max-height: 460px;
    overflow: hidden;

    @include rwd.breakpoint(tabletmobile) {
      max-height: 360px;
      overflow: hidden;
    }

    @include rwd.breakpoint(mobile) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  &__txtBox> :last-child {
    margin-bottom: 40px;
  }

  &__imgBox img {
    width: 100%;
    height: auto;
  }

  &__txtBox>* {
    @include mixin.font(20px, 33px, 400, $c-mine-shaft-dark, 'Fira Sans');

    @include rwd.breakpoint(mobile) {
      @include mixin.font(16px, 28px);
    }
  }

  &__btnWrapper {
    display: flex;
    justify-content: center;
    position: relative;

    &:after {
      @include mixin.position(absolute, false, false, 70%, 0);
      content: '';
      z-index: 1;
      width: 100%;
      height: 70px;
      background: linear-gradient(180deg, rgba(236, 237, 244, 0) 0%, $c-athens-gray 100%);
    }
  }

  &__btn {
    z-index: 2;
    border: solid 1px transparent;

    &:hover,
    &:focus {
      @include rwd.breakpoint(smallUp) {
        background-color: $c-white;
        border-color: var(--main-color);
        color: var(--main-color);
      }
    }
  }
}